import React, { useState } from "react";
import { Button, MenuItem, Select } from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";
import Lottie from "lottie-react";

import Layout from "../components/Layout/Layout";
import * as classes from "../styles/contact.module.scss";
import mainImg from "../images/contact.svg";
import sendIcon from "../images/send.svg";
import locationIcon from "../images/location.svg";
import ani from "../lotti_json/contact_thanks_ani.json";
import Typography from "../components/Typography/Typography";
import { recaptchaSiteKey } from "../constants";

const formStateList = {
  IDLE: "idle",
  LOADING: "loading",
  SUCCESS: "success",
  ERROR: "error",
};

const identityOptions = ["Job seeker", "Employer", "Recruiter"];

export default function Contact() {
  const [formState, setFormState] = useState(formStateList.IDLE);
  const [selectedIdentity, setSelectedIdentity] = useState("");
  const [captchaValue, setCaptchaValue] = useState(null); // State to store captcha response

  const handleIdentityChange = (event) => {
    setSelectedIdentity(event.target.value);
  };

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value); // Captcha response is set here
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    const form = event.target;

    // Check if the reCAPTCHA is validated
    if (!captchaValue) {
      alert("Please validate you are not a robot.");
      return;
    }

    setFormState(formStateList.LOADING);
    const formData = new FormData(form);

    // Remove any unwanted field(s)
    formData.delete("g-recaptcha-response");

    const encodedData = new URLSearchParams(formData).toString();

    fetch(form.action, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: encodedData,
    })
      .then((response) => {
        if (response.ok) {
          setFormState(formStateList.SUCCESS);
          setTimeout(() => {
            form.reset(); // Reset the form
            setFormState(formStateList.IDLE);
        }, 2000);
        } else {
          throw new Error("Submission failed");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setFormState(formStateList.ERROR);
      });
  };

  return (
    <Layout>
      <div className={classes.root}>
        <div className={classes.main_container}>
          <div>
            <Typography variant="h1" align="center">
              Send us a message
            </Typography>
            <Typography variant="h3" align="center">
              Please use the following form to contact us. We will get back to
              you within 24 hours.
            </Typography>
          </div>
          <div className={classes.main_section}>
            <img className={classes.main_img} src={mainImg} alt="Contact Img" />
            <div className={classes.main_info}>
              {formState === formStateList.SUCCESS ? (
                <div className={classes.thankCard}>
                  <Lottie
                    animationData={ani}
                    style={{ width: 185, height: 185 }}
                    background="transparent"
                    speed="1"
                  />
                  <Typography variant="h5">Thank you!</Typography>
                  <Typography variant="p">
                    Your message has been sent.
                  </Typography>
                </div>
              ) : (
                <form
                  onSubmit={handleFormSubmit}
                  method="POST"
                  className={"gform " + classes.contact_form}
                  action="https://script.google.com/macros/s/AKfycby5PM4XLgOmWpDp2hWrRc3qrU0EudDxDae3-xvNMMLC2s5wloHCqFXyRDzIa6HGkTfoIw/exec"
                  data-email="your-email@gmail.com"
                >
                  <input
                    required
                    name="first_name"
                    placeholder="First name"
                    className={classes.input_text}
                  />
                  <input
                    type="text"
                    required
                    name="last_name"
                    placeholder="Last name"
                    className={classes.input_text}
                  />
                  <input
                    type="email"
                    required
                    name="email"
                    placeholder="Email"
                    className={classes.input_text}
                  />
                  <Select
                    name="identity"
                    value={selectedIdentity}
                    displayEmpty
                    onChange={handleIdentityChange}
                    renderValue={(selected) => selected || "Select user type"}
                    className={classes.select}
                  >
                    <MenuItem disabled value="">
                      <em>Select user type</em>
                    </MenuItem>
                    {identityOptions.map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                  <input
                    type="text"
                    required
                    name="subject"
                    placeholder="Subject"
                    className={classes.input_text_subject}
                  />
                  <textarea
                    name="message"
                    required
                    placeholder="Message"
                    className={classes.input_text_area}
                  />
                  <div className={classes.recaptcha_section}>
                    <ReCAPTCHA
                      sitekey={recaptchaSiteKey}
                      onChange={handleCaptchaChange}
                    />
                  </div>
                  <div className={classes.submit_but_container}>
                    <Button
                      type="submit"
                      color="secondary"
                      variant="contained"
                      className={classes.submit_but}
                    >
                      {formState === formStateList.LOADING
                        ? "Sending..."
                        : "Send Message"}
                      <img src={sendIcon} alt="send" />
                    </Button>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
