// extracted by mini-css-extract-plugin
export var root = "contact-module--root--ulXgc";
export var main_container = "contact-module--main_container--mCI0Q";
export var title = "contact-module--title--4snQP";
export var info_text = "contact-module--info_text--1JMJl";
export var main_section = "contact-module--main_section--zB7z3";
export var recaptcha_section = "contact-module--recaptcha_section--oPcM3";
export var main_img = "contact-module--main_img--HNkYL";
export var contact_form = "contact-module--contact_form--SGjRL";
export var input_text = "contact-module--input_text--K+qAY";
export var input_text_subject = "contact-module--input_text_subject--Ywoux";
export var input_text_area = "contact-module--input_text_area--tIZVo";
export var submit_but_container = "contact-module--submit_but_container--5C29k";
export var submit_but = "contact-module--submit_but--kX1z0";
export var postal_address_title = "contact-module--postal_address_title--AAY8L";
export var select = "contact-module--select--BBZZq";
export var option = "contact-module--option--aUoxw";
export var postal_address = "contact-module--postal_address--yKwvf";
export var thankCard = "contact-module--thankCard--wEF+4";
export var main_info = "contact-module--main_info--4-tOc";
export var main_inf0 = "contact-module--main_inf0--ChqNw";